import React from "react";
import Link from "gatsby-link";
import styled from 'styled-components'

import ArrowBack from "../assets/arrowBack.svg";


const ImgContainer = styled.div`
  margin-top: -24px;
  display: flex;
  justify-content: flex-end;
  & > div > div > svg {
    width: 60px;
    height: 60px;
  }
`;


const ContentContainer = styled.div`
  height: 263px;
  border-bottom: solid 2px rgba(255, 255, 255, 0.1);
  margin: 48px 24px 0 24px;
`;


const NameContainer = styled.div`
  color: white;
  margin-top: 140px;
  text-align: start;
  line-height: 22px;
  font-size: 18px;
  text-transform: uppercase;
`;

const TextButtonContainer = styled.div`
  padding: 15px 24px 18px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextButton = styled.div`
  color: #333131;
  font-weight: 500;
  font-size: 16px;
  line-height: 46px;
  text-transform: uppercase;
`;

const BtnImg = styled.div`
  width: 13px;
  height: 25px;
  margin-left: 3px;

  & > svg > path {
    fill: #333131;
  }

`;

const Container = styled.div`
  width: 290px;
  height: 363px;
  border-radius: 16px;
  background-color: #1C1A1A;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 40px;
  
  :hover {
    background-color: #4285F4;

    ${ImgContainer} {
     

      & > div > div > svg > path {
        fill: #ffffff;
      }

      & > svg > g > path {
        fill: #ffffff;
      }
    }

    ${TextButton} {
      color: #ffffff;
    }

    ${BtnImg} {
      & > svg > path {
        fill: #ffffff;
      }
    }

  }

  @media (max-width: 767px) {
    margin-right: 0px;
  }
  @media (max-width: 333px) {
    width: 253px;
  }
`;


const BlackCard = ({item}) => {

    return (
        <Container>
            <Link to={item.link}>
                <ContentContainer>
                    <ImgContainer>
                        {item.img}
                    </ImgContainer>
                    <NameContainer>
                        {item.title}
                    </NameContainer>
                </ContentContainer>
                <TextButtonContainer>
                    <TextButton>
                        Подробнее
                    </TextButton>
                    <BtnImg>
                        <ArrowBack/>
                    </BtnImg>
                </TextButtonContainer>
            </Link>
        </Container>
    );
}

export default BlackCard;
