import React, {useState} from 'react';
import {ReactSVG} from 'react-svg'
import {graphql} from "gatsby";
import Header from "../components/Header/header";
import CarouselProject from "../components/Carousel/CarouselProjects"
import InformationBlock from "../ui/informationBlock";
import InformationBlockList from "../ui/InformationBlockList";
import RunTextComponent from "../components/RunText";
import {ContainerCarousel, ContainerL} from "../ui/containers";
import Footer from "../components/Footer/footer";
import SliderCard from "../components/Slider/SliderCard"
import SliderIncredible from "../components/Slider/SliderIncredible"
import Partners from "../components/Partners/index"
import FormBlock from '../components/Form';
import Control from "../components/Control";
import styled from "styled-components";
import Modal from "../ui/modal";
import Seo from "../components/seo";
import "../components/layout.css";
import PlusComponent from "../ui/plusComponent";


const Svg = styled(ReactSVG)`
  & > div > svg > path {
    fill: #333131
  }
`;


const Index = ({data}) => {

    const [openModal, setOpenModal] = useState(false);
    const projectsData = data.allStrapiProject.nodes;
    const servicesData = data.allStrapiService.nodes;
    const businessData = data.allStrapiBusiness.nodes[0].blocks;
    const allStrapiMainPage = data.allStrapiMainPage.nodes[0];
    const services = servicesData.map(item => {
        return {
            title: item.title,
            img: <Svg src={item.iconSvg.localFile.publicURL}/>,
            link: 'services/' + item.id,
        }
    })

    const carouselData = projectsData.map(item => {
        return {
            title: item.title,
            description: item.description.substr(0, 60) + "...",
            img: item.carousel[0].localFile.publicURL,
            url: item.id
        }
    })
    const business = businessData.map(item => {
        return {
            img: item.image.localFile.publicURL,
            title: item.title,
            description: item.description.substr(0, 100) + "...",
            link: `/business/#business${item.id}`
        }
    })


    const toggleOpenModal = () => {
        if (!openModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        setOpenModal(!openModal);
    }
    return (
        <>
            <Seo title={'Установка умного дома под ключ в Москве'}/>

            <>
                <Header/>
                <Modal openModal={openModal} toggleOpenModal={toggleOpenModal}/>
                <ContainerG>
                    <RunTextComponent/>

                    <InformationBlock
                        title={<div>INTEL BUILDING</div>}
                        text={allStrapiMainPage.about}
                        buttonText={"ПОДРОБНЕЕ О НАС"}
                    />
                </ContainerG>

                <ContainerR>
                    <SliderCard
                        black={true}
                        data={services}
                        title="УСЛУГИ"
                        toggleOpenModal={toggleOpenModal}
                    />
                </ContainerR>

                <ContainerR>
                    <SliderIncredible data={business} toggleOpenModal={toggleOpenModal}/>
                </ContainerR>

                <ContainerCarousel>
                    <ContainerL>
                        <PlusComponent text='проекты'/>
                    </ContainerL>
                    <div id="project">
                        <CarouselProject items={carouselData} maxWidth={2000}/>
                    </div>
                </ContainerCarousel>

                <ContainerL style={{marginBottom: "120px"}}>
                    <Partners/>
                </ContainerL>

                <ContainerL>
                    <Control/>
                </ContainerL>

                <ContainerL style={{margin: "160px auto"}}>
                    <InformationBlockList data={allStrapiMainPage.description}/>
                </ContainerL>

                <ContainerL style={{margin: "150px auto"}}>
                    <FormBlock/>
                </ContainerL>

                <Footer toggleOpenModal={toggleOpenModal}/>
            </>
        </>
    );
}

export const query = graphql`
    query IndexQuery {
        allStrapiProject(sort: {fields: serialNumber}) {
            nodes {
                title
                description
                id
                carousel {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        allStrapiMainPage {
            nodes {
                about
                description
            }
        }
        allStrapiBusiness {
            nodes {
                blocks {
                    id
                    title
                    description
                    image {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
        allStrapiService(sort: {fields: serialNumber}) {
            nodes {
                id
                iconSvg {
                    localFile {
                        publicURL
                    }
                }
                title
            }
        }
    }
`

const ContainerG = styled(ContainerL)`
  margin: 78px auto;
  @media (max-width: 1268px) {
    margin: 20px auto;
    padding: 10px;
  }
`
const ContainerR = styled.div`
  margin: 200px auto;
  padding-left: 150px;
  max-width: 1600px;
  width: 100%;
  @media (max-width: 1268px) {
    padding: 10px;
  }
  @media (max-width: 768px) {
    margin: 100px auto;
  }
`
export default Index;


