import React from 'react';
import styled from "styled-components";
import CircleButton from './button';
import backgroundSchema from "../images/background_schema.png"
import PlusComponent from "./plusComponent";
import '../components/layout.css'

const Wrapper = styled.div`
    min-height:600px;
    position: relative;
`

const Container = styled.div`
  margin-top: 50px;
  color: #FFFFFF;
`;


const MainContent = styled.div`
    width: 648px;
    margin-left:235px;
    @media (max-width: 968px){
        width: fit-content;
        margin-left:12px; 
    }
`

const MainText = styled.div`
    line-height: 36px;
    font-weight: 300;
    font-size:18px;
    @media (max-width: 968px){
        padding-bottom:60px; 
    }
`

const ImgContainer = styled.div`
  z-index: -10;
  margin-top: -90px;
  position: absolute;
  right: 0;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #0B0A0A 0%, rgba(0, 0, 0, 0) 30.99%, #0B0A0A 61.46%);
    opacity: 0.8;
  }

  @media (max-width: 968px) {
    margin-top: 220px;
  }
`

const LargeTransparentText = styled.div`
    font-size:210px;
    position:absolute;
    color:#0D0C0C;
    z-index:-9;
    margin-top:70px;
    margin-left: -200px;
    line-height:220px;
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.55);
    &::selection {
        background: transparent;
        color:#0D0C0C;
     }
    &::-moz-selection {
        background: transparent;
        color:#0D0C0C;
    }
    @media (max-width: 968px){
        font-size: 50px;
        margin-top:10px;
        top: 4px;
        left: 220px;
        line-height: 48px;
    }
`;



const InformationBlock = ({title, text, buttonText}) => {
    return (
        <Wrapper>
            <ImgContainer>
                <img src={backgroundSchema} alt='back-schema' />
            </ImgContainer>
            <PlusComponent text={title}/>
            <Container>
                <MainContent>
                    <MainText>
                        {text}    
                    </MainText>

                    <LargeTransparentText>INTEL <br/> BUILDING</LargeTransparentText>

                    {buttonText && <CircleButton  text={buttonText || "No Text"} link={'/about'}/>}
                    
                </MainContent>
                
            </Container>
        </Wrapper>
    );
};

export default InformationBlock;
