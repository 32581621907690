import React, {useState} from 'react';
import Card from "../../ui/card";
import {Btn} from "../constants";
import Arrow from "../../assets/arrowBack.svg";
import {SliderFlow} from "./SliderFlow";
import styled from "styled-components";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import BlackCard from "../../ui/blackCard";
import '../layout.css'
import PlusComponent from "../../ui/plusComponent";
import SwipeableViews from "react-swipeable-views";
import {virtualize} from 'react-swipeable-views-utils';
import {mod} from 'react-swipeable-views-core';


const VirtualizeSwipeableViews = virtualize(SwipeableViews);

function slideRenderer(params) {
    const {index, key, items, black, toggleOpenModal} = params;
    let item = items[mod(index, items.length)];
    return (
        <div key={key}>
            {black ? (
                <BlackCard item={item}/>
            ) : (
                <Card toggleOpenModal={toggleOpenModal} item={item}/>
            )}
        </div>
    )
}

const SliderCard = ({data, black, title, toggleOpenModal}) => {
        const [value, setValue] = useState(0);
        const screens = useBreakpoint();
        const handleChange = (e) => {
            setValue(e);
        };

        return (
            <>
                <div>
                    <PlusComponent text={title}/>
                    {black ? (
                        <CarouselContainer>
                            {screens.md && (

                                <div
                                    style={
                                        screens.xl === true
                                            ? {
                                                display: "flex",
                                                marginLeft: -200 * value,
                                            }
                                            : {
                                                display: "flex",
                                                marginLeft: -290 * value,
                                            }
                                    }
                                >
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        {data.map((item, index) => (
                                            <div key={index}>
                                                <BlackCard item={item}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </CarouselContainer>
                    ) : (
                        <CarouselContainer cards>
                            {screens.md && (
                                <div
                                    style={screens.xl === true
                                        ? {
                                            display: "flex",
                                            marginLeft: -110 * value,
                                            transition: "1s",
                                        }
                                        : {
                                            display: "flex",
                                            marginLeft: -170 * value,
                                            transition: "1s",
                                        }}
                                >
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        {data.map((item, index) => (
                                            <div key={index}>
                                                <Card
                                                    item={item}
                                                    toggleOpenModal={toggleOpenModal}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </CarouselContainer>
                    )}
                    {!screens.md && (
                        <div>
                            <VirtualizeSwipeableViews style={black ? {padding: '0 25px 0 5px'} : {padding: '0 100px 0 5px'}}
                                                      slideStyle={{overflow: "hidden"}}
                                                      slideRenderer={(e) => slideRenderer({
                                                          ...e,
                                                          toggleOpenModal: toggleOpenModal,
                                                          items: data,
                                                          black: black
                                                      })}/>
                        </div>
                    )}

                    <div
                        style={{display: "flex", flexDirection: "row", marginTop: 10}}
                    >
                        <BtnContainer card={black}>
                            <Btn
                                shape="circle"
                                type="primary"
                                size={"large"}
                                onClick={toggleOpenModal}
                            >
                                <Arrow/>
                                <TextButton>
                                    <A onClick={toggleOpenModal}>ОБСУДИТЬ ПРОЕКТ</A>
                                </TextButton>
                            </Btn>
                        </BtnContainer>
                        {screens.md &&
                        ((data.length > 4 && black) ||
                            ((data.length > 6 || !screens.lg) && !black)) && (
                            <div style={{margin: "50px 0 0 145px"}}>
                                <SliderFlow
                                    width={
                                        screens.xxl
                                            ? "840px"
                                            : screens.xl
                                            ? "600px"
                                            : screens.lg
                                                ? "450px"
                                                : "331px"
                                    }
                                    value={value}
                                    count={data.length}
                                    ChangeCard={handleChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
;

export default SliderCard;


export const BtnContainer = styled.div`
  margin-left: ${props => props.card ? '8px' : '0'};
  margin-top: 35px;
  display: flex;
  margin-right: 210px;
  align-items: center;
  transition-duration: .2s;

  &:hover {
    color: white;
  }
;
  color: #4285F4;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;


const CarouselContainer = styled.div`
  margin-top: 140px;
  margin-left: ${props => props.cards ? '0px' : '21px'};
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

const A = styled.a`
  transition-duration: 0.2s;
  white-space: nowrap;
  color: inherit;

  &:hover {
    color: white;
  }
`;

const TextButton = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-left: 18px;
  color: inherit;
  position: absolute;
  left: 62px;
  text-align: left;
  text-transform: uppercase !important;
  @media (max-width: 600px) {
    font-size: 13px;
    margin-left: 15px;
  }
  @media (max-width: 380px) {
    font-size: 12px;
    margin-left: 12px;
  }
`;
