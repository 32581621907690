import React from 'react';
import styled from "styled-components";
import "../components/layout.css"
import ArrowRightSmall from "../images/ArrowRightSmall.svg";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const ImageCard = ({item}) => {

    return (
        <AnchorLink stripHash to={item.link}>
            <PicContainer src={item.img}>
                <ContainerInfo>
                    <Title>{item.title}</Title>
                    <Description>{item.description}</Description>
                    <Additional>
                        <InfoCardLink stripHash to={item.link}>
                            <Span>ПОДРОБНЕЕ</Span>
                            <Arrow>
                                <img
                                    src={ArrowRightSmall}
                                    style={{marginLeft: "14px"}}
                                    alt="More"
                                />
                            </Arrow>
                        </InfoCardLink>
                    </Additional>
                </ContainerInfo>
            </PicContainer>
        </AnchorLink>
    );
};

export default ImageCard;

const Arrow = styled.div`
  transition-duration: 0.3s;

  &:hover {
    margin-left: 10px;
  }
`;

const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 285px;
  height: 254px;
`


const PicContainer = styled.div`
  z-index: 20;
  min-width: 400px;
  max-width: 401px;
  height: 562px;
  background: linear-gradient(0.29deg, #000000 0.16%, #0B0A0A 0.17%, rgba(11, 10, 10, 0.93) 39.34%, rgba(11, 10, 10, 0.41) 99.66%), url(${props => props.src || ""}) no-repeat center;
  background-size: cover;
  border-radius: 16px;
  margin-right: 40px;
  padding-left: 24px;
  padding-top: 281px;
  @media (max-width: 425px) {
    padding-top: 115px;
    padding-left: 21px;
    min-width: 270px;
    max-width: 270px;
    height: 354px;

    ${ContainerInfo} {
      height: 222px;
      width: 196px;
    }
  }

  @media (max-width: 333px) {
    min-width: 254px;
    max-width: 254px;
    ${ContainerInfo} {
      height: 222px;
      width: 176px;
    }
  }
`


const Title = styled.div`
  color: #FFFFFF;
  font-size: 22px;
  @media (max-width: 425px) {
    font-size: 16px;
  }
`

const Description = styled.div`
  color: #ffffff;
  font-size: 15px;
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

const Additional = styled.div`
  margin-top: 42px;
  color: #FFFFFF;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;
`


const InfoCardLink = styled(AnchorLink)`
  display: flex;
  width: fit-content;
  color: white;
  border-color: #1C1A1A;
  text-decoration: none !important;
  transition: 0.1s border-color;

  &:hover {
    cursor: pointer;
    //border-bottom: 2px solid #4285F4;
    border-radius: 2px;
    text-decoration: none !important;
  }
`;

const Span = styled.span`
  transition-duration: .3s;
  @media (max-width: 425px) {
    font-size: 14px;
  }

  &:hover {
    margin-right: 10px;
  }
`;
