import React, {useState} from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ImageCard from "../../ui/imageCard";
import {SliderFlow} from "./SliderFlow";
import styled from "styled-components";
import PlusComponent from "../../ui/plusComponent";
import SwipeableViews from "react-swipeable-views";
import {virtualize} from 'react-swipeable-views-utils';
import {mod} from 'react-swipeable-views-core';
import {Btn} from "../constants";
import Arrow from "../../assets/arrowBack.svg";
import {BtnContainer} from "./SliderCard";


const VirtualizeSwipeableViews = virtualize(SwipeableViews);

function slideRenderer(params) {
    const {index, key, items} = params;
    let item = items[mod(index, items.length)];
    return (
        <ImageCard key={key} item={item}/>
    )

}

const IncredibleSlider = ({data, toggleOpenModal}) => {
    const [value, setValue] = useState(0);
    const [inProp, setInProp] = useState(false);
    const screens = useBreakpoint();


    const handleChange = (e) => {
        if (Math.ceil(e) === data.length) setValue(data.length - 1)
        else setValue(e);
        setInProp(!inProp)
    };

    return (
        <>
            <Container>
                <PlusComponent text="для кого"/>
                <CarouselContainer>
                    {screens.md && (
                        <>
                            <Container>
                                <div
                                    style={{
                                        display: "flex",
                                        height: 562,
                                        marginLeft: -235 * value,

                                    }}
                                >
                                    <div style={{display: "flex", flexDirection: "row", transition: "1s"}}>
                                        {data.map((item, index) =>
                                            index !== 0 ? (
                                                <div key={index}>
                                                    {
                                                        <ImageCard item={item}/>
                                                    }
                                                </div>
                                            ) : null
                                        )}
                                    </div>
                                </div>


                            </Container>
                        </>)}
                </CarouselContainer>

                {!screens.md &&
                <VirtualizeSwipeableViews style={{padding: '0 25px 0 5px'}} slideStyle={{overflow: "hidden"}}
                                          slideRenderer={(e) => slideRenderer({...e, items: data})}/>
                }

                <div
                    style={{display: "flex", flexDirection: "row", marginTop: 10}}
                >

                    <BtnContainer>
                        <Btn
                            shape="circle"
                            type="primary"
                            size={"large"}
                            onClick={toggleOpenModal}
                        >
                            <Arrow/>
                            <TextButton>
                                <A onClick={toggleOpenModal}>ОБСУДИТЬ ПРОЕКТ</A>
                            </TextButton>
                        </Btn>
                    </BtnContainer>

                    {screens.md &&
                    <div style={{margin: "50px 0 0 145px"}}>
                        <SliderFlow
                            width={
                                screens.xxl
                                    ? "840px"
                                    : screens.xl
                                    ? "600px"
                                    : screens.lg
                                        ? "450px"
                                        : "331px"

                            }
                            value={value}
                            count={data.length}
                            ChangeCard={handleChange}
                        />
                    </div>}

                </div>
            </Container>
        </>
    );
};

export default IncredibleSlider;


const Container = styled.div`
  display: flex;
  flex-direction: column;
`;


const CarouselContainer = styled.div`
  margin-top: 140px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

const A = styled.a`
  transition-duration: 0.2s;
  white-space: nowrap;
  color: inherit;

  &:hover {
    color: white;
  }
`;

const TextButton = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-left: 18px;
  color: inherit;
  position: absolute;
  left: 62px;
  text-align: left;
  text-transform: uppercase !important;
  @media (max-width: 600px) {
    font-size: 13px;
    margin-left: 15px;
  }
  @media (max-width: 380px) {
    font-size: 12px;
    margin-left: 12px;
  }
`;

