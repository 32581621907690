import React from 'react';
import styled from "styled-components";

import '../components/layout.css'
import PlusComponent from "./plusComponent";

const Container = styled.div`
  color: #FFFFFF;
  margin-left: 21px;
  margin-top: 23px;
  @media (max-width: 968px) {
    margin-left: 15px;
    margin-top: 15px;
  }
`;


const LineText = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  white-space: pre-wrap;
  @media (max-width: 312px) {
    max-width: 220px;
  }
`;

const Content = styled.div`
  @media (max-width: 968px) {
    margin-left: 10px;
  }
`


const InformationBlockList = ({data}) => {
    return (
        <>
            <PlusComponent text='Что такое умный дом'/>
            <Container>
                <Content>
                    <LineText>{data}</LineText>
                </Content>
            </Container>
        </>
    );
};

export default InformationBlockList;
