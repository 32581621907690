import React from 'react';
import styled from "styled-components";
import PlusComponent from "../../ui/plusComponent";
import Knx from "../../assets/knx.svg";
import Iridium from "../../assets/iRidium.svg";
import Alice from "../../assets/alice.svg";
import Apple from "../../assets/apple.svg";
import Google from "../../assets/Google.svg";
import Alexa from "../../assets/alexa.svg";

const data = [
    {
        title: 'KNX выключатели',
        svg: <Knx/>,
        link: "/"
    },
    {
        title: 'Iridium mobile App',
        svg: <Iridium/>,
        link: "/"
    },
    {
        title: 'Яндекс Алиса',
        svg: <Alice/>,
        link: "/"
    },
    {
        title: 'Apple HomeKit',
        svg: <Apple/>,
        link: "/"
    },
    {
        title: 'Google Assistant',
        svg: <Google/>,
        link: "/"
    },
    {
        title: 'Amazon Alexa',
        svg: <Alexa/>,
        link: "/"
    },

]


const Control = () => {
    return (
        <>
            <PlusComponent text='программы управления'/>
            <BackgroundText>
                любая <br/> точка
            </BackgroundText>

            <Container>
                <Text>
                    Управляйте своим умным домом из любой точки мира – с планшета или смартфона
                </Text>
                <IconContainer>
                    {
                        data.map((item, index) =>
                            <Icon key={index}>
                                {item.svg}
                                <div style={{fontSize: 16, fontWeight: 300, color: "white"}}>
                                    {item.title}
                                </div>
                            </Icon>
                        )
                    }
                </IconContainer>
            </Container>
        </>
    );
}

export default Control;

const BackgroundText = styled.div`
  font-size: 288px;
  position: absolute;
  color: #0D0C0C;
  text-align: right;
  margin-left: 335px;
  margin-top: -280px;
  text-transform: uppercase;
  z-index: -9;
  font-weight: 300;
  line-height: 320px;
  text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.55);

  &::selection {
    background: transparent;
    color: #0D0C0C;
  }

  &::-moz-selection {
    background: transparent;
    color: #0D0C0C;
  }

  @media (max-width: 1280px) {
    font-size: 200px;
    margin-left: 320px;
    line-height: 250px;
    margin-top: -220px;
  }
  @media (max-width: 1000px) {
    font-size: 150px;
    margin-left: 250px;
    line-height: 150px;
    margin-top: -220px;
  }
  @media (max-width: 768px) {
    font-size: 100px;
    color: #131212;
    margin-left: 200px;
    line-height: 125px;
    margin-top: -250px;
  }
  @media (max-width: 550px) {
    font-size: 72px;
    line-height: 86px;
    margin-left: 100px;
    margin-top: -180px;
  }
  @media (max-width: 365px) {
    margin-left: 15px;
    margin-top: -255px;
  }

`;

const Container = styled.div`
  padding: 35px 0 0 23px;
  display: flex;
  flex-direction: column;
`;
const IconContainer = styled.div`
  max-width: 1255px;
  display: grid;
  margin-top: 40px;
  margin-left: 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 1268px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: -36px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 568px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 375px) {
    margin-left: 0px;
  }
  @media (max-width: 320px) {
    max-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 136px;
  height: 136px;
  @media (max-width: 1268px) {
    margin-top: 76px;
  }

`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 300;
  max-width: 346px;
  height: 90px;
  color: #FFFFFF;
  @media (max-width: 325px) {
    max-width: 220px;

  }

`;
