import React from "react";
import styled from "styled-components";
import '../layout.css'
import logo from '../../images/ib.png'
import CircleButton from "../../ui/button";

const DivOne = styled.div`
  color: white;
  position: relative;
  font-weight: bold;
  font-size: 72px;
  line-height: 96px;
  text-transform: uppercase;
  @media (max-width: 1300px) {
    font-size: 62px;
    line-height: 86px;
  }
  @media (max-width: 800px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

const SpanOne = styled.span`
  color: white;
  font-size: 72px;
  line-height: 96px;
  font-weight: 300;
  text-transform: uppercase;
  @media (max-width: 1300px) {
    font-size: 52px;
    line-height: 86px;
  }
  @media (max-width: 968px) {
    font-size: 38px;
    line-height: 46px;
  }
  @media (max-width: 800px) {
    font-size: 33px;
    line-height: 58px;
  }
`;

const BorderTop = styled.div`
  margin-bottom: 5px;
  width: 111px;
  border-top: 2px solid #4285F4;
`;

const DivTwo = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: white;
  width: 200px;
  @media (max-width: 968px) {
    font-size: 15px;
  }
`;


const Container = styled.div`
  display: flex;
  margin-bottom: 300px;
  @media (max-width: 1300px) {
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 967px) {
    margin-bottom: 150px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
  }
`;


const Prof = styled.div`
  margin-left: 27px;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 31px;
  }
`;

const Block = styled.div`
  margin-top: 73px;
  margin-right: 29px;
  @media (max-width: 800px) {
    margin-top: 0;
    margin-bottom: 34px;
    margin-right: 29px;
  }
  @media (max-width: 300px) {
    margin-right: 0;
  }
`;


const SpanCintainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  width: 635px;
  height: 500px;
  @media (max-width: 1300px) {
    margin-top: 25px;
    width: 600px;
    height: 496px;
  }
  @media (max-width: 1125px) {
    margin-top: 25px;
    width: 500px;
    height: 418px;
  }
  @media (max-width: 1050px) {
    margin-top: 25px;
    width: 450px;
    height: 378px;
  }
  @media (max-width: 981px) {
    margin-top: 25px;
    width: 400px;
    height: 340px;
  }
  @media (max-width: 861px) {
    margin-top: 50px;
    width: 300px;
    height: 285px;
  }

  @media (max-width: 300px) {
    width: 230px;
    height: 231px;

  }

`;


const RunTextComponent = () => {
    const Marquiz = typeof window !== "undefined" && window.Marquiz;
    return (
        <Container>
            <Block>
                <DivOne>умный дом</DivOne>
                <SpanCintainer style={{}}>
                    <SpanOne>в москве</SpanOne>

                    <Prof>
                        <BorderTop/>
                        <DivTwo>Профессиональные инсталляторы систем</DivTwo>
                    </Prof>
                </SpanCintainer>
                <CircleButton
                    text={"Узнать стоимость"}
                    toggleOpenModal={() => Marquiz.showModal('60dc9d431568cf003e72e6ca')}
                />
            </Block>
            <div>
                <Logo src={logo} alt="logo-ib" className="locked"/>
            </div>


        </Container>
    );
}

export default RunTextComponent;
